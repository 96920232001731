import React from "react"

import Header1 from "../components/header1"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import AppleLogo from "../assets/appstores/app-store-1.svg"
import GoogleLogo from "../assets/appstores/google-play-5.svg"

export default function LandingFeatures(props) {
  const data = useStaticQuery(graphql`
    query {
      amazon: file(relativePath: { eq: "appstores/amazon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 80, toFormat: JPG, trim: 10) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      galaxy: file(relativePath: { eq: "appstores/galaxy.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 80, toFormat: PNG, trim: 10) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      huawei: file(relativePath: { eq: "appstores/huawei.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 80, toFormat: PNG, trim: 10) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <section className="bg-white py-20" id="features">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
          <Header1
            headerText="Make more money from your subscriptions today"
            widthClass="w-full md:w-1/2" className="text-center"
          />

          <div className="flex items-center justify-center mx-auto my-10">
            <AppleLogo
              className="md:w-20 md:h-20 w-10 h-10 mx-2 md:mx-5 lg:mx-10"
              alt="Apple App Store"
            />
            <GoogleLogo
              className="md:w-20 md:h-20 w-10 h-10 mx-2 md:mx-5 lg:mx-10"
              alt="Google Play Store"
            />

            <Img
              fluid={data.amazon.childImageSharp.fluid}
              className="md:w-20 md:h-20 w-10 h-10 mx-2 md:mx-5 lg:mx-10"
              alt="Amazon App Store"
            />

            <Img
              fluid={data.galaxy.childImageSharp.fluid}
              className="md:w-20 md:h-20 w-10 h-10 mx-2 md:mx-5 lg:mx-10"
              alt="Samsung Galaxy Store"
            />

            <Img
              fluid={data.huawei.childImageSharp.fluid}
              className="md:w-20 md:h-20 w-10 h-10 mx-2 md:mx-5 lg:mx-10"
              alt="Huawei App Store"
            />
          </div>

          <div className="mt-10">
            <ul className="w-2/3 md:grid md:grid-cols-2 md:gap-10 mx-auto">
              <li className="mb-10 md:mb-0">
                <div>
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 text-xl text-gray-800 bg-l5-primary rounded-md p-3">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="tachometer-alt-fastest"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        className="svg-inline--fa fa-tachometer-alt-fastest fa-w-18"
                      >
                        <path
                          fill="currentColor"
                          d="M120 320c0 13.26-10.74 24-24 24s-24-10.74-24-24 10.74-24 24-24 24 10.74 24 24zm168-168c13.26 0 24-10.74 24-24s-10.74-24-24-24-24 10.74-24 24 10.74 24 24 24zm136 56c13.26 0 24-10.74 24-24s-10.74-24-24-24-24 10.74-24 24 10.74 24 24 24zm71.78 109.38c1.47 8.72-4.44 16.95-13.16 18.41l-131.2 21.87C348.53 390.3 321.41 416 288 416c-35.35 0-64-28.65-64-64s28.65-64 64-64c26.09 0 48.44 15.66 58.41 38.05l130.97-21.83c8.53-1.49 16.93 4.44 18.4 13.16zM320 352c0-17.67-14.33-32-32-32s-32 14.33-32 32 14.33 32 32 32 32-14.33 32-32zM152 160c-13.26 0-24 10.74-24 24s10.74 24 24 24 24-10.74 24-24-10.74-24-24-24zm424 160c0 52.8-14.25 102.26-39.06 144.8-5.61 9.62-16.3 15.2-27.44 15.2h-443c-11.14 0-21.83-5.58-27.44-15.2C14.25 422.26 0 372.8 0 320 0 160.94 128.94 32 288 32s288 128.94 288 288zm-32 0c0-141.16-114.84-256-256-256S32 178.84 32 320c0 45.26 12 89.75 34.7 128.68l442.8-.68C532 409.75 544 365.26 544 320z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  </div>{" "}
                  <div className="mt-4">
                    <h4 className="font-bold text-xl text-gray-800">
                      Easy to get started
                    </h4>{" "}
                    <p className="mt-2 text-base leading-6 text-gray-800">
                      Just connect all app stores to LinkFive and we will
                      provide you with full transparency what is working in your
                      subscription business - and what is not. We provide you
                      with automated reports, insights and suggestions on what
                      to improve.
                    </p>
                  </div>
                </div>
              </li>{" "}
              <li className="mb-10 md:mb-0">
                <div>
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 text-xl text-gray-800 bg-l5-primary rounded-md p-3.5">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="search-dollar"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="svg-inline--fa fa-search-dollar fa-w-16"
                      >
                        <path
                          fill="currentColor"
                          d="M235.09 199.42l-45-13.5c-5.16-1.55-8.77-6.78-8.77-12.73 0-7.27 5.3-13.19 11.8-13.19h28.11c4.56 0 8.96 1.29 12.82 3.72 3.24 2.03 7.36 1.91 10.13-.73l11.75-11.21c3.53-3.37 3.33-9.21-.57-12.14-9.1-6.83-20.08-10.77-31.37-11.35V112c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v16.12c-23.62.63-42.67 20.55-42.67 45.07 0 19.97 12.98 37.81 31.58 43.39l45 13.5c5.16 1.55 8.77 6.78 8.77 12.73 0 7.27-5.3 13.19-11.8 13.19h-28.11c-4.56 0-8.96-1.29-12.82-3.72-3.24-2.03-7.36-1.91-10.13.73l-11.75 11.21c-3.53 3.37-3.33 9.21.57 12.14 9.1 6.83 20.08 10.77 31.37 11.35V304c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-16.12c23.62-.63 42.67-20.54 42.67-45.07 0-19.97-12.98-37.81-31.58-43.39zm273.38 269.46l-121.39-121.4c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-135.99C415.98 93.1 322.88 0 207.99 0S0 93.1 0 207.99c0 114.89 93.1 207.99 207.99 207.99 52 0 99.49-19.1 135.99-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.39 121.39c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.71-4.69 4.71-12.29 0-16.99zm-300.48-100.9c-88.4 0-159.99-71.6-159.99-159.99C48 119.59 119.59 48 207.99 48c88.39 0 159.99 71.6 159.99 159.99 0 88.4-71.6 159.99-159.99 159.99z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  </div>{" "}
                  <div className="mt-4">
                    <h4 className="font-bold text-xl text-gray-800">
                      One workspace to rule them all
                    </h4>{" "}
                    <p className="mt-2 text-base leading-6 text-gray-800">
                      All apps & stores - one workspace. Create, analyse,
                      manage, test and optimize your subscriptions from one
                      single workspace.
                      <br />
                      Your most important metrics: easily accessible,
                      automatically updated and comparable across all your apps
                      and stores.
                    </p>
                  </div>
                </div>
              </li>{" "}
              <li className="mb-10 md:mb-0">
                <div>
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 text-xl text-gray-800 bg-l5-primary rounded-md p-3.5">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="history"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="svg-inline--fa fa-history fa-w-16"
                      >
                        <path
                          fill="currentColor"
                          d="M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659 16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708 22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651 12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058 90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  </div>{" "}
                  <div className="mt-4">
                    <h4 className="font-bold text-xl text-gray-800">
                      No code - No Problem
                    </h4>{" "}
                    <p className="mt-2 text-base leading-6 text-gray-800">
                      Make changes to your subscriptions on all managed apps
                      with one click from our central interface. No need to deal
                      with 3 different app stores just to change a single price.
                    </p>
                  </div>
                </div>
              </li>{" "}
              <li className="mb-10 md:mb-0">
                <div>
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 text-xl text-gray-800 bg-l5-primary rounded-md p-3.5">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="shield-alt"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="svg-inline--fa fa-shield-alt fa-w-16"
                      >
                        <path
                          fill="currentColor"
                          d="M256 409.6V100l-142.9 59.5c8.4 116.2 65.2 202.6 142.9 250.1zM466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256 464C158.5 423.4 64 297.3 64 128l192-80 192 80c0 173.8-98.4 297-192 336z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  </div>{" "}
                  <div className="mt-4">
                    <h4 className="font-bold text-xl text-gray-800">
                      Your data is yours
                    </h4>{" "}
                    <p className="mt-2 text-base leading-6 text-gray-800">
                      All data is safely stores with bank-level safety. As a
                      German company we are fully GDPR compliant. We don't make
                      money from your data - no tracking data is collected.{" "}
                      <Link to="/privacy" className="font-bold">
                        Learn more
                      </Link>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}
