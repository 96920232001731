import React from "react"

Header.defaultProps = {
  className: "",
  widthClass: "w-full"
};

export default function Header(props) {
return <>
    <h2 className={`${ props.className } ${ props.widthClass } py-2 text-5xl font-bold leading-tight text-center text-l5-secondary mx-auto`}>
      {props.headerText}
    </h2>
    <div className="w-full mb-4">
        <div className="h-2 mx-auto bg-l5-primary md:w-96 w-64 my-0 py-0" style={{"marginTop":"-15px"}}></div>
    </div>
  </>
}
