import React from "react"

import Layout from "../components/layout"

import Hero from "../components/landing-hero"
import Benefits from "../components/landing-benefits"
import Features from "../components/landing-features"
import Quotes from "../components/landing-quotes"

export default function Home(props) {
  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true">
      <Hero />

      <Benefits />

      <Quotes />

      <Features />
    </Layout>
  )
}
