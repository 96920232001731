import {Link} from "gatsby";
import logo_invers from "../../assets/logo_invers.png";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import React from "react";

export default function Header(){
    return (
        <nav
            id="header"
            className="fixed w-full z-30 top-0 text-white bg-l5-primary-background"
        >
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
                <div className="pl-4 flex items-center">
                    <Link to="/">
                        <img src={logo_invers} alt="Logo" width="160px" height="29px" />
                    </Link>
                </div>
                <div className="block lg:hidden pr-4">
                    <button
                        id="nav-toggle"
                        className="flex items-center p-1 text-white focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                    >
                        <svg
                            className="fill-current h-6 w-6"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                <div
                    className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-white p-4 lg:p-0 z-20"
                    id="nav-content"
                >
                    <ul className="list-reset lg:flex justify-end flex-1 items-center">
                        <li className="mr-3">
                            <AnchorLink
                                to="/#product"
                                title="Our Product"
                                className="inline-block py-2 px-4 lg:text-white text-l5-secondary font-bold nav-underline"
                            >
                                Product
                            </AnchorLink>
                        </li>
                        <li className="mr-3">
                            <AnchorLink
                                to="/#features"
                                title="Our Product"
                                className="inline-block py-2 px-4 lg:text-white text-l5-secondary font-bold nav-underline"
                            >
                                Features
                            </AnchorLink>
                        </li>
                        <li className="mr-3">
                            <Link
                                to="/pricing"
                                className="inline-block lg:text-white text-l5-secondary font-bold py-2 px-4 nav-underline"
                            >
                                Pricing
                            </Link>
                        </li>
                        {/*
                        <li className="mr-3">
                            <Link
                                to="/docs"
                                className="inline-block lg:text-white text-l5-secondary font-bold py-2 px-4 nav-underline"
                            >
                                Docs
                            </Link>
                        </li>
                        */}
                        <li className="mr-3">
                            <Link
                                to="/faq"
                                className="inline-block lg:text-white text-l5-secondary font-bold py-2 px-4 nav-underline"
                            >
                                FAQ
                            </Link>
                        </li>
                    </ul>
                    <Link
                        to="/contact"
                        className="action-button focus:shadow-outline focus:outline-none"
                        state={{ scheduleDemo: true }}
                    >
                        Schedule a Demo
                    </Link>
                </div>
            </div>
        </nav>
    )
}