import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function LandingQuotes(props) {

  const data = useStaticQuery(graphql`
    query {
      quoteNini: file(relativePath: { eq: "quote_nini.jpg" }) {
        childImageSharp {
          fixed(width: 80, height: 80, toFormat: JPG) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      quoteDimi: file(relativePath: { eq: "quote_dimitri.jpg" }) {
        childImageSharp {
          fixed(width: 80, height: 80, toFormat: JPG) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <section className="bg-l5-highlight">
        <div className="min-w-screen flex flex-wrap items-center justify-center px-5 pb-20 w-3/4 mx-auto">
          <div
            className="w-full mx-auto rounded-lg bg-white shadow-lg px-5 pt-5 pb-10 text-gray-800 mt-20"
            style={{ maxWidth: "500px" }}
          >
            <div className="w-full pt-1 pb-5">
              <div className="overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg">
              <Img
                  fixed={data.quoteNini.childImageSharp.fixed}
                  className="inline object-cover w-20 h-20"
                  alt="Developer Nini"
                />
              </div>
            </div>
            <div className="w-full mb-10">
              <div className="text-3xl text-l5-primary-background text-left leading-tight h-3">
                “
              </div>
              <p className="text-sm text-gray-600 text-center px-5">
                After Packing List was featured in a TV show I had to seize the
                opportunity to monetize the app - fast. After connecting the
                different app stores I discovered how people on the different
                apps behave and created a well working initial subscription
                package thanks to LinkFive.
              </p>
              <div className="text-3xl text-l5-primary-background text-right leading-tight h-3 -mt-3">
                ”
              </div>
            </div>
            <div className="w-full">
              <p className="text-md text-l5-primary-background font-bold text-center">
                Nini La
              </p>
              <p className="text-xs text-gray-500 text-center">
                Creator of{" "}
                <a
                  href="https://packinglist.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Packing List
                </a>
              </p>
            </div>
          </div>

          <div
            className="w-full mx-auto rounded-lg bg-white shadow-lg px-5 pt-5 pb-10 text-gray-800 mt-20"
            style={{ maxWidth: "500px" }}
          >
            <div className="w-full pt-1 pb-5">
              <div className="overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg">
                <Img
                  fixed={data.quoteDimi.childImageSharp.fixed}
                  className="inline object-cover w-20 h-20"
                  alt="Developer Dimi"
                />
              </div>
            </div>
            <div className="w-full mb-10">
              <div className="text-3xl text-l5-primary-background text-left leading-tight h-3">
                “
              </div>
              <p className="text-sm text-gray-600 text-center px-5">
                With LinkFive I was able to get up and running in minutes. My
                app is popular in India but I never knew just how much my
                pricing was wrong for this market. LinkFive helped me setup and
                test various iterations and within a month I had tripled my
                subscription revenue.
              </p>
              <div className="text-3xl text-l5-primary-background text-right leading-tight h-3 -mt-3">
                ”
              </div>
            </div>
            <div className="w-full">
              <p className="text-md text-l5-primary-background font-bold text-center">
                Dimitri Lavrenük
              </p>
              <p className="text-xs text-gray-500 text-center">
                Creator Prana27 - Pranayama Kapalabhati Yoga Breathing
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
