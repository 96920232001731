import React from "react"
import { Link } from "gatsby"

export default function LandingCallToAction(props) {
  return (
    <section>
      <div className="bg-l5-primary-background">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block text-white">Ready to link up?</span>
            <span className="block text-white">
              Schedule a Demo today
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <Link to="/contact" className="action-button focus:shadow-outline focus:outline-none sm:w-auto md:mt-5" state={{ scheduleDemo: true }}>Schedule a Demo</Link>
          </div>
        </div>
      </div>
    </section>
  )
}
