import React from "react"

import Header1 from "../components/header1"

import LearnImage from "../assets/undraw_online_test_gba7.svg"
import DashboardImage from "../assets/undraw_financial_data_es63.svg"
import GrowingImage from "../assets/undraw_growth_curve_8mqx.svg"

export default function LandingBenefits(props) {
  return (
    <>
      <section className="bg-white py-8" id="product">
        <div className="container max-w-6xl mx-auto m-8">
          <Header1
            headerText="The solution to all your subscription business challenges"
            widthClass="w-4/6"
          />
          <div className="flex flex-wrap mt-12">
            <div className="sm:w-1/2 p-6">
              <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                Gain valuable insights
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                Get <strong>instant insights and live updates</strong> of your paying customers.
                Understand when, where, why and how they bought your product.
                <br />
                <br />
                Compare your subscriptions with existing competitors and stay up
                to date with price changes cross-store & across countries.         
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-12">
              <DashboardImage />
            </div>
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row pt-20">
            <div className="w-full sm:w-1/2 p-16">
              <LearnImage />
            </div>

            <div className="sm:w-1/2 p-6">
              <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                Shorten your learning cycles
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                Connect your Apps from{" "}
                <strong>Google, Apple, Amazon, Samsung & Huawei</strong>{" "}
                and manage all your subscriptions in a single workspace.
                <br />
                <br />
                LinkFive will enhance your data with everything you need, so
                that you can make the right decision for your subscription business.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap py-20">
            <div className="sm:w-1/2 p-6">
              <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                Grow your revenues (faster)
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                What are your current customers paying? In which country and
                currency are they buying your product?
                <br />
                <br />
                Understand every detail of your subscriptions and{" "}
                <strong>find out what your customers are willing to pay </strong>
                for your product, in every country, in all currencies and in all
                of your platforms.
                <br/><br/>
                The world‘s economy is changing rapidly, every day. Google,
                Apple and all others are counting on you to stay on top of all
                to tweak your subscriptions accordingly.{" "}
                <strong>We help you to select the right price, automatically</strong>, and
                keep you updated on inflation and market changes, worldwide.
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-24">
              <GrowingImage />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
