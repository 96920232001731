import React from "react"

import { Link } from "gatsby"

import BottomWave from "../assets/BottomWave.svg"
import WorkspaceImage from "../assets/undraw_google_analytics_a57d.svg"

export default function LandingHero(props) {
  return (
    <>
      <div className="pt-24">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mb-20">
          
          <div className="md:ml-14 mb-10 md:mb-0 md:pt-16 md:max-w-2xl md:w-full flex flex-col items-start">
            <h1 className="text-4xl font-extrabold leading-10 tracking-tight sm:text-5xl sm:leading-none md:text-6xl">
              Modern & Smart App Subscription Management
            </h1>

            <p className="my-5 text-base sm:text-lg sm:max-w-xl md:text-xl md:mx-0">
              Link all your Apps to your LinkFive workspace. <br />
              Our insights & tools will magically enhance your subscriptions. <br />
              Launch, learn and repeat in a matter of days, not months!
            </p>

            <Link to="/contact" className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5" state={{ scheduleDemo: true }}>Schedule a Demo</Link>

          </div>

          <div className="w-full md:w-2/5 py-6 lg:ml-auto mx-auto xl:block hidden">
            <WorkspaceImage className="w-full md:w-4/5 z-50 lg:mx-auto" />
          </div>

        </div>
      </div>
      <div className="relative lg:-mt-24">
        <BottomWave />
      </div>
    </>
  )
}
