import {Link} from "gatsby";
import logo from "../../assets/logo.png";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import linkedInIcon from "../../assets/social/linkedin-icon.png";
import React from "react";


export default function Footer() {
    return (
        <footer className="bg-white pt-10">
            <div className="container mx-auto px-8">
                <div className="w-full flex flex-col md:flex-row py-6">
                    <div className="flex-1 mb-6 text-black">
                        <Link to="/">
                            <img src={logo} alt="Logo" width="160px" height="29px"/>
                        </Link>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Product</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <AnchorLink
                                    to="/#product"
                                    title="Our Product"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    Features
                                </AnchorLink>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/pricing"
                                    title="Pricing"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    Pricing
                                </Link>
                            </li>
                            {/*
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/docs"
                                    title="Documentation"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    Documentation
                                </Link>
                            </li>
                            */}
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/faq"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Legal</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/tos"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    Terms of Service
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/privacy"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Company</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/about"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    About us
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/contact"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    Contact
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/imprint"
                                    className="no-underline hover:underline hover:text-gray-500 text-l5-secondary"
                                >
                                    Imprint
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Follow us</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="https://www.linkedin.com/company/linkfive?utm_source=lf-website&utm_medium=socialbtn" rel="nofollow"
                                   title="Follow us on LinkedIn">
                                    <img src={linkedInIcon} alt="LinkedIn logo" width="30px"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}