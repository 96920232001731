import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

import BottomWave from "../assets/BottomWave.svg"
import TopWave from "../assets/svgr/TopWave"
import CallToAction from "../components/call-to-action"
import Footer from "./core/Footer";
import Header from "./core/Header";

Layout.defaultProps = {
  bottomWave: true,
  fullWave: true,
  callToAction: false,
  waveBackgroundColor: "#FFF",
}

export default function Layout(props) {
  useEffect(() => {
    var scrollpos = window.scrollY
    var header = document.getElementById("header")

    // Change Header Color on Scroll
    document.addEventListener("scroll", function () {
      /*Apply classes for slide in bar*/
      scrollpos = window.scrollY
      if (scrollpos > 10) {
        header.classList.add("shadow")
      } else {
        header.classList.remove("shadow")
      }
    })

    // Allow for Menu Toggling on small screen
    var navMenuDiv = document.getElementById("nav-content")
    var navMenu = document.getElementById("nav-toggle")

    document.onclick = check
    function check(e) {
      var target = e && e.target

      //Nav Menu
      if (!checkParent(target, navMenuDiv)) {
        // click NOT on the menu
        if (checkParent(target, navMenu)) {
          // click on the link
          if (navMenuDiv.classList.contains("hidden")) {
            navMenuDiv.classList.remove("hidden")
            navMenu.classList.add("text-l5-primary")
          } else {
            navMenuDiv.classList.add("hidden")
            navMenu.classList.remove("text-l5-primary")
          }
        } else {
          // click both outside link and outside menu, hide menu
          navMenuDiv.classList.add("hidden")
          navMenu.classList.remove("text-l5-primary")
        }
      }
    }
    function checkParent(t, elm) {
      while (t.parentNode) {
        if (t === elm) {
          return true
        }
        t = t.parentNode
      }
      return false
    }
  }, [])

  return (
    <div className="leading-normal tracking-normal text-white bg-l5-primary-background">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>LinkFive - Successful subscriptions</title>
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <script
          defer
          src="https://static.cloudflareinsights.com/beacon.min.js"
          data-cf-beacon='{"token": "b74fa21174e94145800cab3cb26f3adf", "spa": true}'
        ></script>
      </Helmet>

      {/* Navigation  */}
      <Header />

      {/* Content */}
      {props.children}

      {/* Footer */}
      {props.bottomWave === true ? (
        <TopWave backgroundcolor={props.waveBackgroundColor} />
      ) : (
        <></>
      )}
      {props.fullWave === true ? <BottomWave /> : <></>}
      {props.callToAction === false ? <></> : <CallToAction />}

      <Footer />
    </div>
  )
}
